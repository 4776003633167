import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./EmployeeTable.css";

const DisplayEmployees = () => {
  const { companyId } = useParams();
  const [employees, setEmployees] = useState([]);
  const [error, setError] = useState(null);
  const [editingEmployee, setEditingEmployee] = useState(null);
  const [editedEmployee, setEditedEmployee] = useState({
    name: "",
    lastName: "",
    email: "",
    role: "",
    workDuration: "",
  });
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [employeeToDelete, setEmployeeToDelete] = useState({});
  const [selectedEmployeeIds, setSelectedEmployeeIds] = useState([]);
  const urlInputRef = useRef(null);

  // const [formId, setFormId] = useState("");

  const navigate = useNavigate();

  useEffect(() => {
    fetchEmployeeData();
  }, [companyId]);

  const fetchEmployeeData = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/companies/${companyId}/employees`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        console.error("HTTP error response:", errorText);
        setError(`Error: ${response.status}`);
        return;
      }

      const data = await response.json();
      setEmployees(data.employees || []);
    } catch (error) {
      console.error("Failed to fetch employees:", error);
      setError("Failed to fetch employees.");
    }
  };

  const handleDeleteEmployee = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/companies/${companyId}/employees/${employeeToDelete.id}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        console.error("HTTP error response:", errorText);
        setError(`Error: ${response.status}`);
        return;
      }

      await fetchEmployeeData();
      closeDeleteModal();
    } catch (error) {
      console.error("Failed to delete employee:", error);
      setError("Failed to delete employee.");
    }
  };

  const handleEditEmployee = (employee) => {
    setEditingEmployee(employee.id);
    setEditedEmployee({
      name: employee.name,
      lastName: employee.lastName,
      email: employee.email,
      role: employee.role,
      workDuration: employee.workDuration,
    });
  };

  const handleSaveEdit = async () => {
    const token = localStorage.getItem("token");

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/employees/${editingEmployee}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify(editedEmployee),
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        console.error("HTTP error response:", errorText);
        setError(`Error: ${response.status}`);
        return;
      }

      setEditingEmployee(null);
      setEditedEmployee({
        name: "",
        lastName: "",
        email: "",
        role: "",
        workDuration: "",
      });
      fetchEmployeeData();
    } catch (error) {
      console.error("Failed to edit employee:", error);
      setError("Failed to edit employee.");
    }
  };

  const handleCancelEdit = () => {
    setEditingEmployee(null);
    setEditedEmployee({
      name: "",
      lastName: "",
      email: "",
      role: "",
      workDuration: "",
    });
  };

  const openDeleteModal = (employee) => {
    setEmployeeToDelete(employee);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setEmployeeToDelete({});
    setIsDeleteModalOpen(false);
  };

  const handleSelectEmployee = (employeeId) => {
    setSelectedEmployeeIds((prevIds) =>
      prevIds.includes(employeeId)
        ? prevIds.filter((id) => id !== employeeId)
        : [...prevIds, employeeId]
    );
  };
  // const handleAssignEmployees = async () => {
  //   if (selectedEmployeeIds.length === 0) {
  //     console.error("No employees selected.");
  //     alert("No employees selected.");
  //     return;
  //   }

  //   try {
  //     const token = localStorage.getItem("token");

  //     // Fetch the formId associated with the company
  //     const formIdResponse = await fetch(
  //       `http://localhost:5000/api/companies/${companyId}/form-id`,
  //       {
  //         headers: {
  //           Authorization: `Bearer ${token}`,
  //         },
  //       }
  //     );

  //     if (!formIdResponse.ok) {
  //       const errorData = await formIdResponse.text();
  //       console.error("Error fetching form ID:", errorData);
  //       throw new Error(errorData);
  //     }

  //     const { formId } = await formIdResponse.json();

  //     console.log("Fetched Form ID:", formId); // Add this line to debug

  //     if (!formId) {
  //       console.error("No form ID found.");
  //       alert("No form ID found for the company.");
  //       return;
  //     }

  //     // Collect the feedback links and employee names
  //     const feedbackLinks = [];
  //     const employeeNames = {};

  //     // Assign feedback forms to employees
  //     for (const employeeId of selectedEmployeeIds) {
  //       console.log(
  //         "Assigning Form ID:",
  //         formId,
  //         "to Employee ID:",
  //         employeeId
  //       ); // Add this line to debug

  //       const response = await fetch(
  //         "http://localhost:5000/api/feedback/assign",
  //         {
  //           method: "POST",
  //           headers: {
  //             "Content-Type": "application/json",
  //             Authorization: `Bearer ${token}`,
  //           },
  //           body: JSON.stringify({ formId, employeeId }),
  //         }
  //       );

  //       if (!response.ok) {
  //         const errorData = await response.text();
  //         console.error("Error assigning feedback form:", errorData);
  //         throw new Error(errorData);
  //       }

  //       const { employee } = await response.json();

  //       const feedbackLink = `http://localhost:3000/submit-feedback/${formId}/${employeeId}`;
  //       feedbackLinks.push(feedbackLink);

  //       employeeNames[employeeId] = `${employee.name} ${employee.lastName}`;

  //       console.log(
  //         `Employee ID: ${employeeId}, Feedback Link: ${feedbackLink}, Name: ${employee.name} ${employee.lastName}`
  //       );
  //     }

  //     // Set the generated URL to the input field
  //     if (urlInputRef.current) {
  //       urlInputRef.current.value = feedbackLinks.join("\n");
  //     }
  //     // Optionally, handle the feedbackLinks array further
  //     alert(
  //       "Feedback forms assigned successfully. Check the console for feedback links and employee names."
  //     );

  //     // Optionally display the employee names and feedback links on the UI
  //     // Example: setEmployeeNames(employeeNames);
  //   } catch (error) {
  //     console.error("Error:", error);
  //     alert(`Failed to assign feedback forms: ${error.message}`);
  //   }
  // };


  /////////////////////////
  
  const handleAssignEmployees = async () => {
    if (selectedEmployeeIds.length === 0) {
        console.error("No employees selected.");
        alert("No employees selected.");
        return;
    }

    try {
        const token = localStorage.getItem("token");

        // Fetch the formId associated with the company
        const formIdResponse = await fetch(
            `${process.env.REACT_APP_API_URL}/api/companies/${companyId}/form-id`,
            {
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            }
        );

        if (!formIdResponse.ok) {
            const errorData = await formIdResponse.text();
            console.error("Error fetching form ID:", errorData);
            throw new Error(errorData);
        }

        const { formId } = await formIdResponse.json();

        console.log("Fetched Form ID:", formId);

        if (!formId) {
            console.error("No form ID found.");
            alert("No form ID found for the company.");
            return;
        }

        // Collect the feedback links and employee names
        const feedbackLinks = [];
        const employeeNames = {};

        // Assign feedback forms to employees
        for (const employeeId of selectedEmployeeIds) {
            console.log(
                "Assigning Form ID:",
                formId,
                "to Employee ID:",
                employeeId
            );

            const response = await fetch(
                `${process.env.REACT_APP_API_URL}/api/feedback/assign`,
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token}`,
                    },
                    body: JSON.stringify({ formId, employeeId }),
                }
            );

            if (!response.ok) {
                const errorData = await response.text();
                console.error("Error assigning feedback form:", errorData);
                throw new Error(errorData);
            }

            const { employee } = await response.json();

            // Encode formId and employeeId using Base64
            const encodedFormId = btoa(formId);
            const encodedEmployeeId = btoa(employeeId);

            const feedbackLink = `https://feedback.premkokra.com/#/submit-feedback/${encodedFormId}/${encodedEmployeeId}`;
            feedbackLinks.push(feedbackLink);

            employeeNames[employeeId] = `${employee.name} ${employee.lastName}`;

            console.log(
                `Employee ID: ${employeeId}, Feedback Link: ${feedbackLink}, Name: ${employee.name} ${employee.lastName}`
            );

            // New logic to confirm that the email was sent
            console.log(`Email sent to: ${employee.email}`);
        }

        // Set the generated URL to the input field
        if (urlInputRef.current) {
            urlInputRef.current.value = feedbackLinks.join("\n");
        }

        alert("Feedback forms assigned successfully.");
    } catch (error) {
        console.error("Error:", error);
        alert(`Failed to assign feedback forms: ${error.message}`);
    }
};

  //////////////////////

  const handleCreateForm = () => {
    navigate(`/create-form/${companyId}`);
  };

  // const copyToClipboard = (text) => {
  //   navigator.clipboard
  //     .writeText(text)
  //     .then(() => {
  //       console.log("Text copied to clipboard:", text);
  //       alert("URL copied to clipboard!");
  //     })
  //     .catch((err) => {
  //       console.error("Failed to copy text:", err);
  //       alert("Failed to copy URL to clipboard.");
  //     });
  // };

  return (
    // <section className="relative min-h-screen flex items-center justify-center py-8 sm:py-16 md:py-24">
    <div className="container mx-auto px-4 sm:px-8 py-8 transparent-bg shadow-lg relative">
      <h2 className="text-4xl text-gray-800">Employee List</h2>
      {error && <p className="text-red-600 mb-4">{error}</p>}
      <div className="mb-4 flex space-x-2">
        <button
          onClick={() => navigate(`/add-employees/${companyId}`)}
          className="w-full mt-4 py-1 text-lg text-white font-semibold text-center rounded-full bg-blue-400 transition-all hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400 mb-4"
        >
          Add Employee
        </button>

        <button
          onClick={() => navigate(`/all-feedback/${companyId}`)}
          className="w-full mt-4 py-1 text-lg text-white font-semibold text-center rounded-full bg-green-400 transition-all hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400 mb-4"
        >
          Show Feedback
        </button>

        <button
          onClick={handleCreateForm}
          className="w-full mt-4 py-1 text-lg text-white font-semibold text-center rounded-full bg-yellow-400 transition-all hover:bg-yellow-600 focus:outline-none focus:ring-2 focus:ring-yellow-400 mb-4"
        >
          Create Form
        </button>
      </div>
      <div className="overflow-x-auto">
        <table className="min-w-full bg-white shadow-md rounded-lg overflow-hidden">
          <thead>
            <tr>
              <th className="px-6 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                Select
              </th>
              <th className="px-6 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                ID
              </th>
              <th className="px-6 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                Name
              </th>
              <th className="px-6 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                Last Name
              </th>
              <th className="px-6 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                Email
              </th>
              <th className="px-6 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                Role
              </th>
              <th className="px-6 py-3 border-b-2 border-gray-200 bg-gray-100 text-left text-xs font-semibold text-gray-700 uppercase tracking-wider">
                Work Duration
              </th>
              <th className="px-6 py-3 border-b-2 border-gray-200 bg-gray-100"></th>
            </tr>
          </thead>
          <tbody>
            {employees.map((employee, index) => (
              <tr key={employee.id} className="border-b border-gray-200">
                <td className="px-6 py-4 text-sm">
                  <input
                    type="checkbox"
                    checked={selectedEmployeeIds.includes(employee.id)}
                    onChange={() => handleSelectEmployee(employee.id)}
                    className="form-checkbox h-4 w-4 text-blue-600"
                  />
                </td>
                <td className="px-6 py-4 text-sm">{index + 1}</td>
                <td className="px-6 py-4 text-sm">
                  {editingEmployee === employee.id ? (
                    <input
                      type="text"
                      value={editedEmployee.name}
                      onChange={(e) =>
                        setEditedEmployee({
                          ...editedEmployee,
                          name: e.target.value,
                        })
                      }
                      className="border border-gray-300 rounded px-2 py-1"
                    />
                  ) : (
                    employee.name
                  )}
                </td>
                <td className="px-6 py-4 text-sm">
                  {editingEmployee === employee.id ? (
                    <input
                      type="text"
                      value={editedEmployee.lastName}
                      onChange={(e) =>
                        setEditedEmployee({
                          ...editedEmployee,
                          lastName: e.target.value,
                        })
                      }
                      className="border border-gray-300 rounded px-2 py-1"
                    />
                  ) : (
                    employee.lastName
                  )}
                </td>
                <td className="px-6 py-4 text-sm">
                  {editingEmployee === employee.id ? (
                    <input
                      type="email"
                      value={editedEmployee.email}
                      onChange={(e) =>
                        setEditedEmployee({
                          ...editedEmployee,
                          email: e.target.value,
                        })
                      }
                      className="border border-gray-300 rounded px-2 py-1"
                    />
                  ) : (
                    employee.email
                  )}
                </td>
                <td className="px-6 py-4 text-sm">
                  {editingEmployee === employee.id ? (
                    <input
                      type="text"
                      value={editedEmployee.role}
                      onChange={(e) =>
                        setEditedEmployee({
                          ...editedEmployee,
                          role: e.target.value,
                        })
                      }
                      className="border border-gray-300 rounded px-2 py-1"
                    />
                  ) : (
                    employee.role
                  )}
                </td>
                <td className="px-6 py-4 text-sm">
                  {editingEmployee === employee.id ? (
                    <input
                      type="text"
                      value={editedEmployee.workDuration}
                      onChange={(e) =>
                        setEditedEmployee({
                          ...editedEmployee,
                          workDuration: e.target.value,
                        })
                      }
                      className="border border-gray-300 rounded px-2 py-1"
                    />
                  ) : (
                    employee.workDuration
                  )}
                </td>
                <td className="px-6 py-4 text-sm text-right flex space-x-2">
                  {editingEmployee === employee.id ? (
                    <>
                      <button
                        onClick={handleSaveEdit}
                        className="bg-green-500 text-white py-1 px-3 rounded hover:bg-green-600 focus:outline-none focus:ring-2 focus:ring-green-400"
                      >
                        <i className="fa fa-save"></i>
                      </button>
                      <button
                        onClick={handleCancelEdit}
                        className="bg-gray-500 text-white py-1 px-3 rounded hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-400"
                      >
                        <i className="fa fa-times"></i>
                      </button>
                    </>
                  ) : (
                    <>
                      <button
                        onClick={() => handleEditEmployee(employee)}
                        className="bg-blue-500 text-white py-1 px-3 rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-400"
                      >
                        <i className="fa fa-pencil"></i>
                      </button>
                      <button
                        onClick={() => openDeleteModal(employee)}
                        className="bg-red-500 text-white py-1 px-3 rounded hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400"
                      >
                        <i className="fa fa-trash"></i>
                      </button>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="mt-4">
        <button
          onClick={handleAssignEmployees}
          className="w-full py-2 text-lg text-white font-semibold text-center rounded-full bg-indigo-400 transition-all hover:bg-indigo-600 focus:outline-none focus:ring-2 focus:ring-indigo-400 mb-4"
        >
          <i className="fa fa-check"></i> Assign Feedback
        </button>
      </div>

      {/* Modal for confirmation */}
      {isDeleteModalOpen && (
        <div className="fixed inset-0 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded shadow-lg">
            <p className="text-lg">
              Are you sure you want to delete {employeeToDelete.name}?
            </p>
            <div className="mt-4">
              <button
                onClick={handleDeleteEmployee}
                className="bg-red-500 text-white py-2 px-4 rounded hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-400"
              >
                <i className="fa fa-trash"></i> Delete
              </button>
              <button
                onClick={closeDeleteModal}
                className="bg-gray-500 text-white py-2 px-4 rounded hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-gray-400 ml-2"
              >
                <i className="fa fa-times"></i> Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
    // </section>
  );
};

export default DisplayEmployees;
