import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Slider from "@mui/material/Slider";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import "./FeedbackForm.css"; // Import the CSS file

// import { Slider, Tooltip, Typography } from '@material-ui/core'; // Assuming you're using Material-UI for Slider

const SubmitFeedbackPage = () => {
  const { formId, employeeId } = useParams();
  const [employeeName, setEmployeeName] = useState("");
  const [questions, setQuestions] = useState([]);
  const [responses, setResponses] = useState({});
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    const fetchEmployeeName = async () => {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/employees/${employeeId}`
        );

        if (!response.ok) {
          throw new Error("Failed to fetch employee details");
        }

        const data = await response.json();
        setEmployeeName(`${data.Name} ${data.lastName}`);
      } catch (error) {
        console.error("Error fetching employee name:", error);
      }
    };

    const fetchQuestions = async () => {
      if (!formId) {
        setError("Form ID is required.");
        setLoading(false);
        return;
      }

      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/feedback-forms/${formId}`
        );

        if (!response.ok) {
          const errorData = await response.json();
          console.error("Error response data:", errorData);
          throw new Error(errorData.message || "Failed to fetch questions");
        }

        const data = await response.json();

        if (process.env.NODE_ENV === "development") {
          console.log("Fetched data:", data);
        }

        const questionsWithParsedOptions = (data.questions || []).map(
          (question) => {
            let parsedOptions = [];
            try {
              parsedOptions = Array.isArray(question.options)
                ? question.options
                : JSON.parse(question.options);
            } catch (e) {
              console.error("Error parsing options:", e);
            }
            return {
              ...question,
              options: parsedOptions,
            };
          }
        );

        setQuestions(questionsWithParsedOptions);
        setCompanyId(data.companyId);

        const initialResponses = questionsWithParsedOptions.reduce(
          (acc, question) => {
            acc[question.questionId] = "";
            return acc;
          },
          {}
        );
        setResponses(initialResponses);
      } catch (error) {
        setError("Failed to fetch questions.");
        console.error("Error fetching questions:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchEmployeeName();
    fetchQuestions();
  }, [formId, employeeId]);

  const handleResponseChange = (questionId, value) => {
    setResponses((prevResponses) => ({
      ...prevResponses,
      [questionId]: value,
    }));
  };

  const handleSubmit = async () => {
    if (!formId || !employeeId || !companyId) {
      setError("Form ID, Employee ID, and Company ID are required.");
      return;
    }

    setLoading(true);

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/feedback/submit`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ formId, employeeId, companyId, responses }),
        }
      );

      if (response.ok) {
        setIsSubmitted(true); // Set the submission state to true
      } else {
        const errorData = await response.json();
        alert("Failed to submit feedback: " + errorData.message);
      }
    } catch (error) {
      alert("Failed to submit feedback.");
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return <div>Loading questions...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }

  // return (
  //   <div className="relative">
  //     <div className="container mx-auto px-4 sm:px-8 py-8">
  //       <h2 className="text-2xl font-semibold leading-tight mb-4">
  //         Submit Feedback
  //       </h2>
  //       {employeeName && (
  //         <h3 className="text-xl font-medium mb-4">{employeeName}</h3>
  //       )}

  //       {questions.length > 0 ? (
  //         questions.map((question) => (
  //           <div
  //             key={question.questionId}
  //             className="mb-4 p-4 border rounded-lg shadow-md bg-white"
  //           >
  //             <p className="text-lg font-medium mb-2">
  //               <strong>{question.question}</strong>
  //             </p>

  //             {question.type === "rating" && (
  //               <div className="flex items-center space-x-2">
  //                 <Typography gutterBottom>Rating</Typography>
  //                 <Tooltip title={`${responses[question.questionId] || 0}`}>
  //                   <Slider
  //                     value={Number(responses[question.questionId]) || 0}
  //                     min={1}
  //                     max={5}
  //                     step={1}
  //                     onChange={(e, value) =>
  //                       handleResponseChange(question.questionId, value)
  //                     }
  //                     valueLabelDisplay="auto"
  //                     aria-labelledby="continuous-slider"
  //                     className="w-full"
  //                   />
  //                 </Tooltip>
  //               </div>
  //             )}

  //             {question.type === "multiple_choice" && (
  //               <select
  //                 value={responses[question.questionId] || ""}
  //                 onChange={(e) =>
  //                   handleResponseChange(question.questionId, e.target.value)
  //                 }
  //                 className="w-full p-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
  //               >
  //                 <option value="">Select an option</option>
  //                 {Array.isArray(question.options) ? (
  //                   question.options.map((option, i) => (
  //                     <option key={i} value={option}>
  //                       {option}
  //                     </option>
  //                   ))
  //                 ) : (
  //                   <option value="">No options available</option>
  //                 )}
  //               </select>
  //             )}

  //             {question.type === "textual" && (
  //               <textarea
  //                 value={responses[question.questionId] || ""}
  //                 onChange={(e) =>
  //                   handleResponseChange(question.questionId, e.target.value)
  //                 }
  //                 placeholder="Enter your feedback"
  //                 className="w-full p-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
  //               />
  //             )}

  //             {question.type === "yes_no" && (
  //               <select
  //                 value={responses[question.questionId] || ""}
  //                 onChange={(e) =>
  //                   handleResponseChange(question.questionId, e.target.value)
  //                 }
  //                 className="w-full p-2 border rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
  //               >
  //                 <option value="">Select Yes or No</option>
  //                 <option value="Yes">Yes</option>
  //                 <option value="No">No</option>
  //               </select>
  //             )}
  //           </div>
  //         ))
  //       ) : (
  //         <p className="text-gray-600">No questions available.</p>
  //       )}

  //       <button
  //         onClick={handleSubmit}
  //         className="mt-4 px-4 py-2 bg-blue-500 text-white font-semibold rounded-lg shadow-md hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
  //       >
  //         Submit Feedback
  //       </button>
  //     </div>

  //     {isSubmitted && (
  //       <div className="fixed inset-0 bg-black bg-opacity-90 flex items-center justify-center z-50">
  //         <h1 className="text-white text-4xl font-bold">Thanks!</h1>
  //       </div>
  //     )}
  //   </div>
  // );

  return (
    <div className="feedback-form ">
      <div className="feedback-form__container max-w-screen-lg mx-auto bg-cover bg-center bg-no-repeat flex flex-col md:flex-row shadow-lg rounded-lg overflow-hidden">
        <div className="feedback-form__questions w-full p-4 md:p-6 overflow-y-auto max-h-screen">
          <header className="feedback-form__header mb-4">
            <h1 className="text-lg md:text-2xl text-gray-800">
              Drop us your feedback.
            </h1>
          </header>

          {employeeName && (
            <h3 className="feedback-form__employee-name text-sm md:text-lg text-gray-600 mb-2">
              {employeeName}
            </h3>
          )}

          <main className="feedback-form__main">
            {questions.length > 0 ? (
              questions.map((question) => (
                <div
                  key={question.questionId}
                  className="feedback-form__question-card mb-4 animate-slideIn"
                >
                  <p className="feedback-form__question-text text-sm md:text-base text-gray-700 mb-2">
                    <strong>{question.question}</strong>
                  </p>

                  {question.type === "rating" && (
                    <div className="feedback-form__rating mb-2">
                      <Typography
                        gutterBottom
                        className="feedback-form__rating-text"
                      >
                        Rating
                      </Typography>
                      <div className="feedback-form__rating-slider">
                        <Tooltip
                          title={`${responses[question.questionId] || 0}`}
                        >
                          <Slider
                            value={Number(responses[question.questionId]) || 0}
                            min={0}
                            max={5}
                            // step={1}
                            onChange={(e, value) =>
                              handleResponseChange(question.questionId, value)
                            }
                            valueLabelDisplay="auto"
                            aria-labelledby="continuous-slider"
                          />
                        </Tooltip>
                      </div>
                    </div>
                  )}

                  {question.type === "multiple_choice" && (
                    <select
                      value={responses[question.questionId] || ""}
                      onChange={(e) =>
                        handleResponseChange(
                          question.questionId,
                          e.target.value
                        )
                      }
                      className="feedback-form__select block w-full p-2 border border-gray-300 rounded"
                    >
                      <option value="">Select an option</option>
                      {Array.isArray(question.options) ? (
                        question.options.map((option, i) => (
                          <option key={i} value={option}>
                            {option}
                          </option>
                        ))
                      ) : (
                        <option value="">No options available</option>
                      )}
                    </select>
                  )}

                  {question.type === "textual" && (
                    <textarea
                      value={responses[question.questionId] || ""}
                      onChange={(e) =>
                        handleResponseChange(
                          question.questionId,
                          e.target.value
                        )
                      }
                      className="feedback-form__textarea block w-full p-2 border border-gray-300 rounded"
                      placeholder="Enter your feedback"
                    />
                  )}

                  {question.type === "yes_no" && (
                    <select
                      value={responses[question.questionId] || ""}
                      onChange={(e) =>
                        handleResponseChange(
                          question.questionId,
                          e.target.value
                        )
                      }
                      className="feedback-form__select block w-full p-2 border border-gray-300 rounded"
                    >
                      <option value="">Select Yes or No</option>
                      <option value="Yes">Yes</option>
                      <option value="No">No</option>
                    </select>
                  )}
                </div>
              ))
            ) : (
              <p className="feedback-form__no-questions text-sm text-gray-500">
                No questions available.
              </p>
            )}
          </main>

          <div className="feedback-form__submit mt-4">
            <button
              className="feedback-form__submit-button w-full bg-blue-500 text-white py-2 rounded hover:bg-blue-600 transition duration-200"
              onClick={handleSubmit}
            >
              Send Feedback
            </button>
          </div>
        </div>
      </div>

      {isSubmitted && (
        <div className="feedback-form__submitted-overlay fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg text-center">
            <h1 className="feedback-form__submitted-text text-2xl text-green-600 mb-4">
              Thanks!
            </h1>
            <button
              className="btn btn-primary bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition duration-200"
              onClick={() => (window.location.hash = "#target-tab")}
            >
              Done
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default SubmitFeedbackPage;
