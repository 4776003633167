import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useHistory } from "react-router-dom"; // Example for routing

import "./loginform.css";

const LoginForm = ({ setUserRole }) => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    const userData = {
      email: email,
      password: password,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/login`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(userData),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      console.log("Login successful:", data);
      localStorage.setItem("token", data.token);

      const { role, companyId } = data;
      setUserRole(role);

      // Redirect based on role and companyId
      if (role === "company owner") {
        console.log("Redirecting to /add-employees with companyId:", companyId);
        navigate(`/add-employees/${companyId}`);
      } else if (role === "admin") {
        console.log("Redirecting to /register");
        navigate("/add-company");
      } else {
        console.log("Redirecting to /");
        navigate("/");
      }
    } catch (error) {
      console.error("Login failed with error:", error);
      setError("Login failed. Please try again.");
    }
  };

  return (
    <section className="relative min-h-screen bg-cover flex items-center justify-center py-28 sm:py-0">
      <div className="p-4 sm:p-8 flex-1">
        <div className="max-w-[420px] min-w-[320px] bg-white rounded-3xl mx-auto shadow-xl">
          <div className="px-10 pt-4 pb-8 rounded-3xl shadow-lg relative">
            <div className="mx-auto text-center">
              <h1 className="text-4xl mb-5 text-gray-800">Login</h1>
            </div>
            <form onSubmit={handleLogin} className="space-y-4 md:space-y-6">
              <div className="relative">
                <input
                  id="email"
                  name="email"
                  type="email"
                  className="peer w-full px-0.5 border-0 border-b-2 border-gray-300 placeholder-transparent focus:ring-0 focus:border-purple-600"
                  placeholder="name@company.com"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <label
                  htmlFor="email"
                  className="absolute left-0 -top-3.5 text-gray-600 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2 peer-focus:-top-3.5 peer-focus:text-purple-600 peer-focus:text-sm"
                >
                  Email
                </label>
              </div>
              <div className="mt-10 relative">
                <input
                  id="password"
                  name="password"
                  type="password"
                  className="peer w-full px-0.5 border-0 border-b-2 border-gray-300 placeholder-transparent focus:ring-0 focus:border-purple-600"
                  placeholder="Password"
                  required
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
                <label
                  htmlFor="password"
                  className="absolute left-0 -top-3.5 text-gray-600 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2 peer-focus:-top-3.5 peer-focus:text-purple-600 peer-focus:text-sm"
                >
                  Password
                </label>
              </div>
              <div className="text-right mt-2">
                <a
                  href="#/forgot-password"
                  className="text-sm text-purple-600 hover:text-purple-800"
                >
                  Forgot password?
                </a>
              </div>
              <button
                type="submit"
                className="w-full mt-14 py-4 text-lg text-white font-semibold text-center rounded-full bg-purple-400 transition-all hover:bg-purple-600 focus:outline-none"
              >
                Sign in
              </button>
              {error && (
                <div className="mt-4 text-center text-red-500">{error}</div>
              )}
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default LoginForm;
