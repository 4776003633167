import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import feedbackLogo from "../feedbacklogo.jpg"; // Ensure you have your own logo image path
import "./Home.css"; // Custom CSS for additional styling

function HomePage() {
  const navigate = useNavigate();
  const [menuOpen, setMenuOpen] = useState(false);

  const scrollToSection = (sectionId) => {
    document.getElementById(sectionId).scrollIntoView({ behavior: "smooth" });
  };

  return (
    <div className="antialiased min-h-screen flex flex-col">
      {/* Header */}
      <header className="sticky top-0 z-50 bg-white shadow-md">
        <nav className="flex items-center justify-between w-full py-3 px-4">
          <div>
            <a href="#">
              <img
                src={feedbackLogo}
                alt="Feedback Logo"
                width="60"
                height="22"
                className="inline-block"
              />
            </a>
          </div>

          <div className="md:hidden">
            {/* Burger Menu for Mobile */}
            <button
              className="text-gray-700 focus:outline-none"
              onClick={() => setMenuOpen(!menuOpen)}
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16m-7 6h7"
                ></path>
              </svg>
            </button>
          </div>

          <div
            className={`md:flex ${menuOpen ? "block" : "hidden"} md:items-center`}
          >
            <ul className="flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-8 text-gray-700">
              <li>
                <a
                  className="hover:text-gray-900 cursor-pointer"
                  onClick={() => scrollToSection("about")}
                >
                  About
                </a>
              </li>
              <li>
                <a
                  className="hover:text-gray-900 cursor-pointer"
                  onClick={() => scrollToSection("services")}
                >
                  Services
                </a>
              </li>
              <li>
                <a
                  className="hover:text-gray-900 cursor-pointer"
                  onClick={() => scrollToSection("contact")}
                >
                  Contact
                </a>
              </li>
              <li>
                <a
                  className="hover:text-gray-900"
                  onClick={() => navigate(`/login`)}
                >
                  Login
                </a>
              </li>
            </ul>
          </div>
        </nav>
      </header>

      {/* Main content with animation */}
      <main className="flex-grow flex items-center justify-center text-center px-4 h-screen bg-cover bg-center relative">
          <div className="absolute inset-0 bg-white opacity-60"></div> {/* Faint background */}
          <div className="relative z-10 animate-fade-in-up">
            <div className="flex flex-col md:flex-row items-center justify-center space-x-4">
              <img
                src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/1457119/feedback-form-template.svg"
                alt="Feedback SVG"
                className="w-32 md:w-48 h-auto mb-4 md:mb-0"
              />
              <div className="text-left">
                <h1 className="text-5xl font-bold text-purple-600 drop-shadow-md animate-pulse">
                  Welcome to Our Feedback System!
                </h1>
                <h2 className="text-2xl font-extrabold leading-tight text-gray-900 mt-4 animate-fade-in">
                  Elevate your company with insightful feedback.
                </h2>
              </div>
            </div>
            <p className="mt-4 text-lg text-gray-600 animate-fade-in-delayed">
              Say goodbye to guesswork. Empower your employees to share their
              thoughts and ideas effortlessly with our responsive and
              user-friendly feedback system.
            </p>
            <button
              className="mt-10 py-4 px-10 text-lg text-white font-semibold text-center rounded-full bg-purple-500 transition-all hover:bg-purple-600 focus:outline-none"
              onClick={() => navigate(`/login`)}
            >
              Login
            </button>
          </div>
        </main>

      {/* About Section */}
      <section id="about" className="py-16 bg-white min-h-screen">
        <div className="container mx-auto px-6 flex flex-col md:flex-row items-center">
          <div className="flex-1 md:pr-6 mb-8 md:mb-0">
            <h2 className="text-3xl font-bold text-center md:text-left text-gray-800 mb-6">
              About Our Feedback System
            </h2>
            <p className="text-lg text-center md:text-left text-gray-600 mb-6">
              Our feedback system empowers multiple companies to manage their employee feedback processes seamlessly. Each company can create and customize feedback forms, assign them to employees, and gather valuable insights through an intuitive platform.
            </p>
            <p className="text-lg text-center md:text-left text-gray-600 mb-6">
              Employees can provide feedback efficiently, while companies can track and analyze responses to improve their workplace environment. The system also features average scoring per employee, offering a clear overview of feedback trends and performance metrics.
            </p>
          </div>
          <div className="flex-1 flex justify-center">
            <img 
              src="https://ibdec.net/wp-content/uploads/2018/08/como-dar-feedback-de-verdade.jpg" 
              alt="Feedback and Review Concept" 
              className="w-1/2 h-auto object-cover" 
            />
          </div>
        </div>
      </section>

      {/* Services Section */}
      <section id="services" className="py-20 bg-gray-100">
  <div className="container mx-auto px-6">
    <h2 className="text-3xl font-bold text-center text-gray-800 mb-8">
      Key Features
    </h2>
    <div className="flex flex-wrap -mx-4">
      <div className="w-full md:w-1/3 px-4 mb-4 md:mb-0">
        <div className="bg-white p-6 shadow-lg rounded-lg text-center transition-transform transform hover:scale-105">
          <div className="mb-4 text-purple-600">
            <i className="fas fa-building fa-3x"></i>
          </div>
          <h3 className="text-xl font-semibold mb-4">
            Multi-Company Support
          </h3>
          <p className="text-gray-600">
            Each company can have its own login, manage employees, and track feedback.
          </p>
        </div>
      </div>

      <div className="w-full md:w-1/3 px-4 mb-4 md:mb-0">
        <div className="bg-white p-6 shadow-lg rounded-lg text-center transition-transform transform hover:scale-105">
          <div className="mb-4 text-purple-600">
            <i className="fas fa-users fa-3x"></i>
          </div>
          <h3 className="text-xl font-semibold mb-4">
            Employee Management
          </h3>
          <p className="text-gray-600">
            Add employees, assign feedback forms, and monitor their responses easily.
          </p>
        </div>
      </div>

      <div className="w-full md:w-1/3 px-4 mb-4 md:mb-0">
        <div className="bg-white p-6 shadow-lg rounded-lg text-center transition-transform transform hover:scale-105">
          <div className="mb-4 text-purple-600">
            <i className="fas fa-question-circle fa-3x"></i>
          </div>
          <h3 className="text-xl font-semibold mb-4">
            Custom Feedback Questions
          </h3>
          <p className="text-gray-600">
            Create feedback forms tailored to your company’s needs and assign them to employees.
          </p>
        </div>
      </div>
    </div>
  </div>
</section>


      {/* Contact Section */}
      <section id="contact" className="py-0 bg-white min-h-[60vh] flex items-center justify-center">
        <div className="text-center">
          <h2 className="text-2xl font-bold text-gray-800 mb-0">Contact Us</h2>
          <p className="text-lg text-gray-600 mb-0">
            Have any questions? Reach out to us at:
          </p>
          <p className="text-lg text-gray-700 font-bold mb-0">
            Email: support@feedbacksystem.com
          </p>
          <p className="text-lg text-gray-700 font-bold mb-0">
            Phone: +1 (555) 123-4567
          </p>
        </div>
      </section>

      {/* Footer */}
      <footer className="py-4 bg-gray-800 text-white text-center">
        <p>&copy; 2024 Feedback System. All rights reserved.</p>
      </footer>
    </div>
  );
}

export default HomePage;
