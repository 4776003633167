import React, { useState, useEffect } from "react";

const WorkDurationInput = ({ workDuration, setWorkDuration }) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  useEffect(() => {
    // Set default end date to today and reset start date when workDuration is reset
    if (workDuration === "") {
      const today = new Date().toISOString().split("T")[0];
      setStartDate(""); // Reset startDate
      setEndDate(today);
    }
  }, [workDuration]);
  
  const calculateWorkDuration = (start, end) => {
    if (start && end) {
      const startDate = new Date(start);
      const endDate = new Date(end);
      const diffTime = Math.abs(endDate - startDate);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      return diffDays;
    }
    return "";
  };

  const handleStartDateChange = (e) => {
    const newStartDate = e.target.value;
    setStartDate(newStartDate);
    const duration = calculateWorkDuration(newStartDate, endDate);
    setWorkDuration(duration);
  };

  return (
    <div>
      <input
        type="date"
        value={startDate}
        onChange={handleStartDateChange}
        placeholder="Start Date"
      />
      {/* Hidden End Date Input */}
      <input
        type="date"
        value={endDate}
        style={{ display: "none" }} // Hide the end date input
        readOnly
      />
      <input
        type="text"
        readOnly
        value={workDuration ? `${workDuration} days` : "Work Duration"}
      />
    </div>
  );
};

export default WorkDurationInput;
