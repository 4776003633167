import React from 'react';

const AboutPage = () => {
  return (
    <div className="bg-gray-100 min-h-screen">
      {/* Hero Section */}
      <section className="bg-blue-600 text-white py-20">
        <div className="container mx-auto px-6 text-center">
          <h1 className="text-4xl font-bold mb-6">
            Welcome to the Company Feedback System
          </h1>
          <p className="text-lg mb-6">
            Manage employee feedback effortlessly. Create questions, assign forms, and track responses.
          </p>
          <a
            href="/register"
            className="bg-white text-blue-600 font-semibold py-3 px-6 rounded-lg hover:bg-gray-100 transition duration-300"
          >
            Get Started
          </a>
        </div>
      </section>

      {/* Features Section */}
      <section className="py-16">
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-bold text-center text-gray-800 mb-12">
            Key Features
          </h2>
          <div className="flex flex-wrap -mx-4">
            <div className="w-full md:w-1/3 px-4 mb-8">
              <div className="bg-white p-6 shadow-lg rounded-lg text-center">
                <div className="mb-4 text-blue-600">
                  <i className="fas fa-building fa-3x"></i>
                </div>
                <h3 className="text-xl font-semibold mb-4">Multi-Company Support</h3>
                <p className="text-gray-600">
                  Each company can have their own login, manage employees, and track feedback.
                </p>
              </div>
            </div>

            <div className="w-full md:w-1/3 px-4 mb-8">
              <div className="bg-white p-6 shadow-lg rounded-lg text-center">
                <div className="mb-4 text-blue-600">
                  <i className="fas fa-users fa-3x"></i>
                </div>
                <h3 className="text-xl font-semibold mb-4">Employee Management</h3>
                <p className="text-gray-600">
                  Add employees, assign feedback forms, and monitor their responses with ease.
                </p>
              </div>
            </div>

            <div className="w-full md:w-1/3 px-4 mb-8">
              <div className="bg-white p-6 shadow-lg rounded-lg text-center">
                <div className="mb-4 text-blue-600">
                  <i className="fas fa-question-circle fa-3x"></i>
                </div>
                <h3 className="text-xl font-semibold mb-4">Custom Feedback Questions</h3>
                <p className="text-gray-600">
                  Create feedback forms tailored to your company’s needs and assign them to employees.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="bg-gray-100 py-16">
        <div className="container mx-auto px-6">
          <h2 className="text-3xl font-bold text-center text-gray-800 mb-12">
            What Our Users Are Saying
          </h2>
          <div className="flex flex-wrap -mx-4">
            <div className="w-full md:w-1/2 px-4 mb-8">
              <div className="bg-white p-6 shadow-lg rounded-lg">
                <p className="text-gray-600 mb-4">
                  "This feedback system has completely transformed how we manage employee feedback. It's easy to use and very efficient!"
                </p>
                <h4 className="font-semibold">- CEO of XYZ Corp</h4>
              </div>
            </div>

            <div className="w-full md:w-1/2 px-4 mb-8">
              <div className="bg-white p-6 shadow-lg rounded-lg">
                <p className="text-gray-600 mb-4">
                  "We love the flexibility of creating custom feedback forms for different departments. Highly recommend!"
                </p>
                <h4 className="font-semibold">- HR Manager at ABC Inc</h4>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Call to Action Section */}
      <section className="bg-blue-600 text-white py-12">
        <div className="container mx-auto px-6 text-center">
          <h2 className="text-3xl font-bold mb-6">Ready to Get Started?</h2>
          <p className="text-lg mb-6">
            Join us and streamline your company’s feedback process today.
          </p>
          <a
            href="/register"
            className="bg-white text-blue-600 font-semibold py-3 px-6 rounded-lg hover:bg-gray-100 transition duration-300"
          >
            Sign Up Now
          </a>
        </div>
      </section>
    </div>
  );
};

export default AboutPage;
