import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

const AddCompany = () => {
  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [role, setRole] = useState("company owner");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
    }
  }, [navigate]);

  const handleRegister = async (e) => {
    e.preventDefault();

    const userData = {
      name,
      companyName,
      email,
      password,
      role,
    };

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/register`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(userData),
        }
      );

      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }

      const data = await response.json();
      console.log("Registration successful:", data);

      // Save the token received from the server to localStorage
      localStorage.setItem("token", data.token);

      // Navigate to the DisplayCompany component
      navigate("/display-company");
    } catch (error) {
      console.error("Registration failed with error:", error);
      setError("Registration failed. Please try again.");
    }
  };

  return (
    <section className="bg-gray-50 dark:bg-gray-900">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <div className="w-full bg-white rounded-lg shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <h1 className="text-2xl text-gray-800">Register for an account</h1>
            {error && <div className="text-red-500">{error}</div>}
            <form className="space-y-4 md:space-y-6" onSubmit={handleRegister}>
              <div className="relative">
                <input
                  type="text"
                  name="name"
                  id="name"
                  placeholder=" "
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  required
                  className="block w-full px-4 py-2 border-0 border-b-2 border-gray-300 focus:ring-0 focus:border-purple-600 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white peer"
                />
                <label
                  htmlFor="name"
                  className="absolute text-sm text-gray-600 dark:text-gray-400 transform -translate-y-6 scale-75 top-3 left-4 origin-[0] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-2 peer-focus:scale-75 peer-focus:-translate-y-6 transition-all duration-300"
                >
                  Your Name
                </label>
              </div>

              {role === "company owner" && (
                <div className="relative mt-6">
                  <input
                    type="text"
                    name="companyName"
                    id="companyName"
                    placeholder=" "
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                    required
                    className="block w-full px-4 py-2 border-0 border-b-2 border-gray-300 focus:ring-0 focus:border-purple-600 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white peer"
                  />
                  <label
                    htmlFor="companyName"
                    className="absolute text-sm text-gray-600 dark:text-gray-400 transform -translate-y-6 scale-75 top-3 left-4 origin-[0] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-2 peer-focus:scale-75 peer-focus:-translate-y-6 transition-all duration-300"
                  >
                    Company Name
                  </label>
                </div>
              )}

              <div className="relative mt-6">
                <input
                  type="email"
                  name="email"
                  id="email"
                  placeholder=" "
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                  className="block w-full px-4 py-2 border-0 border-b-2 border-gray-300 focus:ring-0 focus:border-purple-600 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white peer"
                />
                <label
                  htmlFor="email"
                  className="absolute text-sm text-gray-600 dark:text-gray-400 transform -translate-y-6 scale-75 top-3 left-4 origin-[0] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-2 peer-focus:scale-75 peer-focus:-translate-y-6 transition-all duration-300"
                >
                  Your Email
                </label>
              </div>

              <div className="relative mt-6">
                <input
                  type="password"
                  name="password"
                  id="password"
                  placeholder=" "
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                  className="block w-full px-4 py-2 border-0 border-b-2 border-gray-300 focus:ring-0 focus:border-purple-600 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white peer"
                />
                <label
                  htmlFor="password"
                  className="absolute text-sm text-gray-600 dark:text-gray-400 transform -translate-y-6 scale-75 top-3 left-4 origin-[0] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-2 peer-focus:scale-75 peer-focus:-translate-y-6 transition-all duration-300"
                >
                  Password
                </label>
              </div>

              <div className="relative mt-6">
                <select
                  name="role"
                  id="role"
                  value={role}
                  onChange={(e) => setRole(e.target.value)}
                  required
                  className="block w-full px-4 py-2 border-0 border-b-2 border-gray-300 focus:ring-0 focus:border-purple-600 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white peer"
                >
                  <option value="company owner">Company Owner</option>
                  <option value="admin">Admin</option>
                </select>
                <label
                  htmlFor="role"
                  className="absolute text-sm text-gray-600 dark:text-gray-400 transform -translate-y-6 scale-75 top-3 left-4 origin-[0] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-2 peer-focus:scale-75 peer-focus:-translate-y-6 transition-all duration-300"
                >
                  Role
                </label>
              </div>
              <button
  type="submit"
  className="w-full py-2 mt-14 text-lg text-white font-semibold text-center rounded-full bg-purple-400 transition-all hover:bg-purple-500 focus:outline-none"
>
  Register
</button>

            </form>
            <button
              onClick={() => navigate("/display-company")}
              className="w-full text-primary-600 hover:underline dark:text-primary-500 mt-4"
            >
              Show All Companies
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};
export default AddCompany;
