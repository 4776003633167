import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import AddEmployees from "./AddEmployees";
import DisplayEmployees from "./DisplayEmployees";
import "./MainComponent.css";

const MainComponent = () => {
  const { companyId } = useParams();
  const [companyName, setCompanyName] = useState("");
  const [showAddEmployee, setShowAddEmployee] = useState(false);
  const [showEmployeeList, setShowEmployeeList] = useState(false);

  useEffect(() => {
    fetchCompanyName();
  }, [companyId]);

  const fetchCompanyName = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/companies/${companyId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      setCompanyName(data.name);
    } catch (error) {
      console.error("Failed to fetch company name:", error);
    }
  };

  const handleShowAddEmployee = () => {
    setShowAddEmployee(true);
    setShowEmployeeList(false);
  };

  const handleShowEmployeeList = () => {
    setShowEmployeeList(true);
    setShowAddEmployee(false);
  };

  return (
    <div className="container">
      <h2>Company: {companyName}</h2>
      <div className="button-container">
        <button onClick={handleShowAddEmployee}>Add Employee</button>
        <button onClick={handleShowEmployeeList}>Show Employees</button>
      </div>
      <div className="content-container">
        {showAddEmployee && <AddEmployees />}
        {showEmployeeList && <DisplayEmployees />}
      </div>
    </div>
  );
};

export default MainComponent;
