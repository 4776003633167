import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import "./AddEmployees.css"; // Make sure to import the CSS file
import WorkDurationInput from "./WorkDurationInput";

const AddEmployees = () => {
  const { companyId } = useParams();
  const [companyName, setCompanyName] = useState("");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [newEmployee, setNewEmployee] = useState({
    name: "",
    lastName: "",
    email: "",
    role: "",
    workDuration: "",
  });
  const [employees, setEmployees] = useState([]);
  const [error, setError] = useState(null); // State to handle errors
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (!token) {
      navigate("/");
      return;
    }
    fetchCompany();
  }, [companyId, navigate]);

  const fetchCompany = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/companies/${companyId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();
      console.log("Fetched company name:", data.name);
      setCompanyName(data.name);
    } catch (error) {
      console.error("Failed to fetch company:", error);
    }
  };

  const handleAddEmployee = () => {
    const { name, lastName, email, role, workDuration } = newEmployee;

    if (!name || !lastName || !email || !role || !workDuration) {
      alert("Please fill in all employee fields.");
      return;
    }

    // Check for duplicate emails
    const duplicate = employees.find((emp) => emp.email === email);
    if (duplicate) {
      setError(`Duplicate email found: ${email}`);
      return;
    }

    // Add new employee to the list
    setEmployees([...employees, newEmployee]);

    // Clear input fields and reset WorkDurationInput
    setNewEmployee({
      name: "",
      lastName: "",
      email: "",
      role: "",
      workDuration: "",
    });

    setError(null); // Reset error if any
  };

  const handleDeleteEmployee = (index) => {
    const updatedEmployees = employees.filter((_, i) => i !== index);
    setEmployees(updatedEmployees);
  };

  const handleSubmit = async () => {
    const token = localStorage.getItem("token");

    if (employees.length === 0) {
      alert("Please add at least one employee.");
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/companies/${companyId}/employees`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ employees }),
        }
      );

      if (!response.ok) {
        const errorData = await response.json();
        console.error("HTTP error response:", errorData);

        // Check if the error is a validation error related to duplicate emails
        if (errorData.details === "Validation error") {
          setError(
            `Backend error: ${errorData.error}. Details: ${errorData.details}`
          );
        } else {
          setError(
            `HTTP error response: ${errorData.error}. Details: ${errorData.details}`
          );
        }
        return;
      }

      const data = await response.json();
      console.log("Response from server:", data);
      navigate(`/display-employees/${companyId}`);
    } catch (error) {
      console.error("Failed to submit employees:", error);
      setError(`Failed to submit employees: ${error.message}`);
    }
  };

  const handleShowEmployees = () => {
    navigate(`/display-employees/${companyId}`);
  };

  return (
<section className="relative min-h-screen flex items-center justify-center py-8 sm:py-16 md:py-24">
<div className="w-full max-w-2xl p-4 sm:p-6 md:p-8 mx-auto">
        <div className="bg-white rounded-lg shadow-xl dark:bg-gray-800 dark:border-gray-700">
          <div className="p-4 space-y-6 sm:p-6">
            {error && (
              <div className="text-red-500 text-sm text-center">{error}</div>
            )}
            <div className="text-center">
              <h1 className="text-4xl text-gray-800">Company: {companyName}</h1>
              <h6 className="text-2xl text-gray-800">Add Employee</h6>
            </div>
            <form className="space-y-6 sm:space-y-8">
              <div className="relative">
                <input
                  type="text"
                  id="name"
                  placeholder=" "
                  value={newEmployee.name}
                  onChange={(e) =>
                    setNewEmployee({ ...newEmployee, name: e.target.value })
                  }
                  className="block w-full px-4 py-2 border-0 border-b-2 border-gray-300 focus:ring-0 focus:border-purple-600 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white peer"
                />
                <label
                  htmlFor="name"
                  className="absolute text-sm text-gray-600 dark:text-gray-400 transform -translate-y-6 scale-75 top-3 left-4 origin-[0] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-2 peer-focus:scale-75 peer-focus:-translate-y-6 transition-all duration-300"
                >
                  First Name
                </label>
              </div>
              <div className="relative">
                <input
                  type="text"
                  id="lastName"
                  placeholder=" "
                  value={newEmployee.lastName}
                  onChange={(e) =>
                    setNewEmployee({ ...newEmployee, lastName: e.target.value })
                  }
                  className="block w-full px-4 py-2 border-0 border-b-2 border-gray-300 focus:ring-0 focus:border-purple-600 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white peer"
                />
                <label
                  htmlFor="lastName"
                  className="absolute text-sm text-gray-600 dark:text-gray-400 transform -translate-y-6 scale-75 top-3 left-4 origin-[0] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-2 peer-focus:scale-75 peer-focus:-translate-y-6 transition-all duration-300"
                >
                  Last Name
                </label>
              </div>
              <div className="relative">
                <input
                  type="email"
                  id="email"
                  placeholder=" "
                  value={newEmployee.email}
                  onChange={(e) =>
                    setNewEmployee({ ...newEmployee, email: e.target.value })
                  }
                  className="block w-full px-4 py-2 border-0 border-b-2 border-gray-300 focus:ring-0 focus:border-purple-600 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white peer"
                />
                <label
                  htmlFor="email"
                  className="absolute text-sm text-gray-600 dark:text-gray-400 transform -translate-y-6 scale-75 top-3 left-4 origin-[0] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-2 peer-focus:scale-75 peer-focus:-translate-y-6 transition-all duration-300"
                >
                  Email
                </label>
              </div>
              <div className="relative">
                <input
                  type="text"
                  id="role"
                  placeholder=" "
                  value={newEmployee.role}
                  onChange={(e) =>
                    setNewEmployee({ ...newEmployee, role: e.target.value })
                  }
                  className="block w-full px-4 py-2 border-0 border-b-2 border-gray-300 focus:ring-0 focus:border-purple-600 bg-gray-50 dark:bg-gray-700 text-gray-900 dark:text-white peer"
                />
                <label
                  htmlFor="role"
                  className="absolute text-sm text-gray-600 dark:text-gray-400 transform -translate-y-6 scale-75 top-3 left-4 origin-[0] peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-2 peer-focus:scale-75 peer-focus:-translate-y-6 transition-all duration-300"
                >
                  Role
                </label>
              </div>
              <WorkDurationInput
                workDuration={newEmployee.workDuration}
                setWorkDuration={(value) =>
                  setNewEmployee({ ...newEmployee, workDuration: value })
                }
              />

              <button
                type="button"
                className="w-full mt-14 py-2 text-lg text-white font-semibold text-center rounded-full bg-purple-400 transition-all hover:bg-purple-600 focus:outline-none"
                onClick={handleAddEmployee}
              >
                Add Employee
              </button>
              <h6 className="text-2xl text-gray-800 mt-6">
                New Employees List
              </h6>
              <div className="overflow-x-auto">
                <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400">
                  <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
                    <tr>
                      <th scope="col" className="px-4 py-3">
                        Name
                      </th>
                      <th scope="col" className="px-4 py-3">
                        Last Name
                      </th>
                      <th scope="col" className="px-4 py-3">
                        Email
                      </th>
                      <th scope="col" className="px-4 py-3">
                        Role
                      </th>
                      <th scope="col" className="px-4 py-3">
                        Work Duration
                      </th>
                      <th scope="col" className="px-4 py-3">
                        Actions
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {employees.map((emp, index) => (
                      <tr
                        key={index}
                        className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                      >
                        <td className="px-4 py-2">{emp.name}</td>
                        <td className="px-4 py-2">{emp.lastName}</td>
                        <td className="px-4 py-2">{emp.email}</td>
                        <td className="px-4 py-2">{emp.role}</td>
                        <td className="px-4 py-2">{emp.workDuration}</td>
                        <td className="px-4 py-2">
                          <button
                            onClick={() => handleDeleteEmployee(index)}
                            className="text-red-600 hover:text-red-900"
                          >
                            Delete
                          </button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
              <button
                className="w-full mt-14 py-2 text-lg text-white font-semibold text-center rounded-full bg-purple-400 transition-all hover:bg-purple-600 focus:outline-none mb-4"
                onClick={handleSubmit}
              >
                Submit
              </button>
              <div className="flex flex-col space-y-4 sm:flex-row sm:space-y-0 sm:space-x-4">
                <button
                  className="w-full py-2 text-lg text-white font-semibold text-center rounded-full bg-green-400 transition-all hover:bg-green-600 focus:outline-none"
                  onClick={handleShowEmployees}
                >
                  Show All Employees
                </button>
                <button
                  className="w-full py-2 text-lg text-white font-semibold text-center rounded-full bg-green-400 transition-all hover:bg-green-600 focus:outline-none"
                  onClick={() => navigate(`/all-feedback/${companyId}`)}
                >
                  Show Feedback
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AddEmployees;
