import React, { useState } from "react";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const [resetUrl, setResetUrl] = useState(""); // State to store the reset URL

  const handleForgotPassword = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/forgot-password`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (!response.ok) {
        throw new Error(data.message || "Something went wrong");
      }

      setMessage("Password reset link sent to your email.");
      setResetUrl(data.resetUrl); // Set the reset URL from the response
    } catch (error) {
      setError(error.message);
    }
  };

  return (
    <section className="relative min-h-screen bg-cover flex items-center justify-center py-28 sm:py-0">
      <div className="p-4 sm:p-8 flex-1">
        <div className="max-w-[420px] min-w-[320px] bg-white rounded-3xl mx-auto shadow-xl">
          <div className="px-10 pt-4 pb-8 rounded-3xl shadow-lg relative">
            <div className="mx-auto text-center">
              <h1 className="text-4xl mb-5 text-gray-800">Forgot Password</h1>
            </div>
            <form onSubmit={handleForgotPassword} className="space-y-4 md:space-y-6">
              <div className="relative">
                <input
                  id="email"
                  name="email"
                  type="email"
                  className="peer w-full px-0.5 border-0 border-b-2 border-gray-300 placeholder-transparent focus:ring-0 focus:border-purple-600"
                  placeholder="name@company.com"
                  required
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                <label
                  htmlFor="email"
                  className="absolute left-0 -top-3.5 text-gray-600 text-sm transition-all peer-placeholder-shown:text-base peer-placeholder-shown:text-gray-400 peer-placeholder-shown:top-2 peer-focus:-top-3.5 peer-focus:text-purple-600 peer-focus:text-sm"
                >
                  Email
                </label>
              </div>
              <button
                type="submit"
                className="w-full mt-14 py-4 text-lg text-white font-semibold text-center rounded-full bg-purple-400 transition-all hover:bg-purple-600 focus:outline-none"
              >
                Send Reset Link
              </button>
              {message && <div className="mt-4 text-center text-green-500">{message}</div>}
              {error && <div className="mt-4 text-center text-red-500">{error}</div>}
              {resetUrl && ( // Display the reset URL if available
                <div className="mt-4 text-center">
                  <p className="text-gray-800">Reset URL:</p>
                  <a href={resetUrl} className="text-blue-500 underline">{resetUrl}</a>
                </div>
              )}
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ForgotPassword;
