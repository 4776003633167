import React, { useState } from "react";
import { useParams } from "react-router-dom";

const ResetPassword = () => {
  const { token } = useParams(); // Get token from URL params
  const [newPassword, setNewPassword] = useState(""); // Define newPassword state
  const [confirmPassword, setConfirmPassword] = useState(""); // Define confirmPassword state
  const [message, setMessage] = useState(""); // Define message state
  const [error, setError] = useState(""); // Define error state

  const handleResetPassword = async (e) => {
    e.preventDefault();

    if (newPassword !== confirmPassword) {
      setError("Passwords do not match.");
      return;
    }

    try {
      console.log("Sending reset request to:", `${process.env.REACT_APP_API_URL}/api/reset-password/${token}`);
      
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/reset-password/${token}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ newPassword }),
      });

      const data = await response.json();

      console.log("Response from server:", data); // Add this log

      if (!response.ok) {
        throw new Error(data.message || "Something went wrong");
      }

      setMessage("Your password has been reset successfully.");
      setError("");
    } catch (error) {
      console.error("Error during reset:", error.message); // Log the error
      setError(error.message);
      setMessage("");
    }
  };

  return (
    <section className="min-h-screen flex items-center justify-center">
      <div className="max-w-md w-full p-6 bg-white rounded-lg shadow-md">
        <h2 className="text-2xl font-semibold text-center mb-6">Reset Password</h2>
        <form onSubmit={handleResetPassword} className="space-y-6">
          <div className="relative">
            <input
              type="password"
              id="newPassword"
              className="peer w-full px-2 py-1 border-b-2 border-gray-300 placeholder-transparent focus:outline-none focus:border-purple-600"
              placeholder="New Password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
            <label
              htmlFor="newPassword"
              className="absolute left-0 -top-4 text-sm text-gray-600 transition-all peer-placeholder-shown:top-2 peer-placeholder-shown:text-gray-400 peer-focus:-top-4 peer-focus:text-purple-600"
            >
              New Password
            </label>
          </div>

          <div className="relative">
            <input
              type="password"
              id="confirmPassword"
              className="peer w-full px-2 py-1 border-b-2 border-gray-300 placeholder-transparent focus:outline-none focus:border-purple-600"
              placeholder="Confirm Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
            <label
              htmlFor="confirmPassword"
              className="absolute left-0 -top-4 text-sm text-gray-600 transition-all peer-placeholder-shown:top-2 peer-placeholder-shown:text-gray-400 peer-focus:-top-4 peer-focus:text-purple-600"
            >
              Confirm Password
            </label>
          </div>

          <button
            type="submit"
            className="w-full py-3 bg-purple-600 text-white rounded-lg hover:bg-purple-700"
          >
            Reset Password
          </button>
          {message && <div className="mt-4 text-green-500 text-center">{message}</div>}
          {error && <div className="mt-4 text-red-500 text-center">{error}</div>}
        </form>
      </div>
    </section>
  );
};

export default ResetPassword;
