import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import "./DisplayCompany.css"; // Import your CSS file

const DisplayCompany = () => {
  const [companies, setCompanies] = useState([]);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  useEffect(() => {
    const fetchCompanies = async () => {
      try {
        const token = localStorage.getItem("token");
        if (!token) {
          setError("No token found. Please login again.");
          setTimeout(() => navigate("/login"), 3000); // Redirect after 3 seconds
          return;
        }

        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/companies`,
          {
            method: "GET",
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token}`, // Include the token in the Authorization header
            },
          }
        );

        if (!response.ok) {
          const errorText = await response.text();
          throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const data = await response.json();
        setCompanies(data.companies || []); // Ensure 'data.companies' exists and is an array
      } catch (error) {
        setError(
          error.message || "Failed to fetch companies. Please try again."
        );
      }
    };

    fetchCompanies();
  }, [navigate]);

  const handleLogout = () => {
    localStorage.removeItem("token");
    navigate("/");
  };

  return (
    <div className="bg-gray-100 py-24 sm:py-32">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="text-center max-w-2xl mx-auto">
          <h2 className="text-4xl font-extrabold text-gray-900 sm:text-5xl">
            All Companies
          </h2>
          <p className="mt-4 text-lg leading-8 text-gray-600">
            Here is a list of all the companies available in our system.
          </p>
        </div>
        {error && <div className="error-message text-red-600">{error}</div>}
        <button
          type="button"
          className="w-full mt-14 py-2 text-lg text-white font-semibold text-center rounded-full bg-purple-400 transition-all hover:bg-purple-500 focus:outline-none"
          onClick={handleLogout}
        >
          Logout
        </button>

        <ul
          role="list"
          className="mt-12 grid gap-12 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4"
        >
          {companies.map((company, index) => (
            <li
              key={company.id}
              className="bg-white rounded-lg shadow-lg overflow-hidden transform transition-transform hover:scale-105"
            >
              <img
                alt={company.name}
                src={
                  index === 0
                    ? "public/monk.jpg"
                    : index === 1
                    ? "/public/zerovaega.jpg"
                    : company.imageUrl || "https://via.placeholder.com/300"
                }
                className="w-full h-48 object-cover object-center"
              />
              <div className="p-6">
                <h3 className="text-xl font-semibold text-gray-900 mb-2">
                  {company.name}
                </h3>
                <p className="text-sm font-medium text-indigo-600">
                  Company Owner
                </p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default DisplayCompany;
