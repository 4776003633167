import React, { useState } from "react";
import {
  Routes,
  Route,
  HashRouter, // Choose between HashRouter or BrowserRouter
  // BrowserRouter, // Comment out or remove if using HashRouter
} from "react-router-dom";

import AddCompany from "./component/company/AddCompany";
import AddEmployees from "./component/employee/AddEmployees";
import DisplayEmployees from "./component/employee/DisplayEmployees";
import Loginform from "./component/login/loginform";
import ForgotPassword from "./component/login/forgotPassword";
import ResetPassword from "./component/login/ResetPassword";
import OwnerDashboard from "./component/employee/AddEmployees"; // Should point to OwnerDashboard component
import AdminDashboard from "./component/company/AddCompany"; // Should point to AdminDashboard component
// import ProtectedRoutes from "./utils/ProtectedRoutes";
import MainComponent from "./component/employee/MainComponent";
import DisplayCompany from "./component/company/DisplayCompany";
import CreateQuestionsPage from "./feedback/CreateQuestionsPage";
import SubmitFeedbackPage from "./feedback/SubmitFeedbackPage";
import AllFeedbackTable from "./feedback/AllFeedbackTable";

import Home from "./pages/Home";
import About from "./pages/About";


const App = () => {
  const [userRole, setUserRole] = useState(null);

  return (
    <HashRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/login"
          element={<Loginform setUserRole={setUserRole} />}
        />
        {userRole === "company owner" && (
          <Route
            path="/add-employees/:companyId"
            element={<OwnerDashboard />}
          />
        )}
        {userRole === "admin" && (
          <Route path="/add-company" element={<AdminDashboard />} />
        )}
        <Route path="/add-company" element={<AddCompany />} />
        <Route path="/company/:companyId" element={<MainComponent />} />
        <Route path="/add-employees/:companyId" element={<AddEmployees />} />
        <Route
          path="/display-employees/:companyId"
          element={<DisplayEmployees />}
        />
        <Route path="/display-company" element={<DisplayCompany />} />
        <Route
          path="/create-form/:companyId"
          element={<CreateQuestionsPage />}
        />
        <Route
          path="/submit-feedback/:formId/:employeeId"
          element={<SubmitFeedbackPage />}
        />
        <Route path="/all-feedback/:companyId" element={<AllFeedbackTable />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/about" element={<About />} />

        </Routes>
    </HashRouter>
  );
};

export default App;
