import React, { useState, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";

const CreateQuestionsPage = () => {
  const { companyId } = useParams();
  const location = useLocation();
  const [company, setCompany] = useState(null);
  const [questions, setQuestions] = useState([
    { text: "", type: "rating", options: [""] },
  ]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [employeeIds, setEmployeeIds] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    // Fetch company data
    const fetchCompanyData = async () => {
      const token = localStorage.getItem("token");
      try {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}/api/companies/${companyId}`,
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );
        if (response.ok) {
          const data = await response.json();
          setCompany(data);
        } else {
          setError("Failed to fetch company data.");
        }
      } catch (error) {
        setError("Failed to fetch company data.");
      } finally {
        // setLoading(false); // Set loading to false after fetching
      }
    };

    fetchCompanyData();

    // Parse query parameters
    const queryParams = new URLSearchParams(location.search);
    const ids = queryParams.get("employeeIds");
    if (ids) {
      setEmployeeIds(ids.split(","));
    }
  }, [companyId, location.search]);

  const handleQuestionChange = (index, field, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index][field] = value;
    setQuestions(updatedQuestions);
  };

  const handleOptionChange = (questionIndex, optionIndex, value) => {
    const updatedQuestions = [...questions];
    updatedQuestions[questionIndex].options[optionIndex] = value;
    setQuestions(updatedQuestions);
  };

  const handleAddQuestion = () => {
    setQuestions([...questions, { text: "", type: "rating", options: [""] }]);
  };

  const handleAddOption = (index) => {
    const updatedQuestions = [...questions];
    updatedQuestions[index].options.push("");
    setQuestions(updatedQuestions);
  };

  // const handleSaveQuestions = async () => {
  //   setLoading(true); // Set loading to true when starting the request
  //   const token = localStorage.getItem("token");
  //   try {
  //     const response = await fetch(
  //       `${process.env.REACT_APP_API_URL}/api/companies/${companyId}/questions`,
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //         body: JSON.stringify({ questions, employeeIds }), // Include companyId in the request
  //       }
  //     );

  //     if (response.ok) {
  //       const data = await response.json();
  //       const submitFeedbackUrl = `https://feeebacksystem4321.netlify.app/submit-feedback/${data.formId}`;
  //       console.log(`Generated URL: ${submitFeedbackUrl}`); // Log the URL instead of navigating

  //       navigate(`/display-employees/${companyId}`);

  //       // Optionally, you could redirect to the feedback page with the formId
  //       // window.location.href = submitFeedbackUrl; // Uncomment if you want to navigate automatically
  //     } else {
  //       const errorData = await response.json();
  //       setError("Failed to save questions: " + errorData.message);
  //     }
  //   } catch (error) {
  //     console.error("Error:", error);
  //     setError("Failed to save questions");
  //   } finally {
  //     setLoading(false); // Set loading to false after the request is complete
  //   }
  // };

  const handleSaveQuestions = async () => {
    setLoading(true); // Set loading to true when starting the request
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/companies/${companyId}/questions`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // Authorization header is removed for no authentication
          },
          body: JSON.stringify({ questions, employeeIds }), // Include companyId in the request
        }
      );

      if (response.ok) {
        const data = await response.json();
        const submitFeedbackUrl = `https://feedback.premkokra.com/#/submit-feedback/${data.formId}`;
        console.log(`Generated URL: ${submitFeedbackUrl}`); // Log the URL instead of navigating

        navigate(`/display-employees/${companyId}`);

        // Optionally, you could redirect to the feedback page with the formId
        // window.location.href = submitFeedbackUrl; // Uncomment if you want to navigate automatically
      } else {
        const errorData = await response.json();
        setError("Failed to save questions: " + errorData.message);
      }
    } catch (error) {
      console.error("Error:", error);
      setError("Failed to save questions");
    } finally {
      setLoading(false); // Set loading to false after the request is complete
    }
  };



  return (
    <div className="container mx-auto px-4 sm:px-8 py-8">
      <h2 className="text-2xl font-semibold mb-6">
        Create Questions for {company ? company.name : "Company"}
      </h2>
      {questions.map((question, index) => (
        <div key={index} className="bg-white rounded-lg shadow-md p-6 mb-4">
          <input
            type="text"
            value={question.text}
            onChange={(e) =>
              handleQuestionChange(index, "text", e.target.value)
            }
            placeholder="Enter question text"
            className="block w-full mb-4 p-2 border border-gray-300 rounded-lg bg-gray-50 text-gray-900 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
          />
          <select
            value={question.type}
            onChange={(e) =>
              handleQuestionChange(index, "type", e.target.value)
            }
            className="block w-full mb-4 p-2 border border-gray-300 rounded-lg bg-gray-50 text-gray-900 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
          >
            <option value="rating">Rating (1-5)</option>
            <option value="multiple_choice">Multiple Choice</option>
            <option value="textual">Textual Feedback</option>
            <option value="yes_no">Yes/No</option>
          </select>

          {question.type === "multiple_choice" && (
            <div>
              {question.options.map((option, i) => (
                <input
                  key={i}
                  type="text"
                  value={option}
                  onChange={(e) => handleOptionChange(index, i, e.target.value)}
                  placeholder={`Enter option ${i + 1}`}
                  className="block w-full mb-2 p-2 border border-gray-300 rounded-lg bg-gray-50 text-gray-900 focus:ring-primary-500 focus:border-primary-500 dark:bg-gray-700 dark:border-gray-600 dark:text-white"
                />
              ))}
              <button
                onClick={() => handleAddOption(index)}
                className="inline-block mt-2 px-4 py-2 text-sm font-medium text-white bg-primary-600 rounded-lg hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
              >
                Add Option
              </button>
            </div>
          )}
        </div>
      ))}
      <button
        onClick={handleAddQuestion}
        className="inline-block mt-4 px-4 py-2 text-sm font-medium text-white bg-primary-600 rounded-lg hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
      >
        Add Question
      </button>
      <button
        onClick={handleSaveQuestions}
        className="inline-block mt-4 ml-4 px-4 py-2 text-sm font-medium text-white bg-primary-600 rounded-lg hover:bg-primary-700 focus:ring-4 focus:outline-none focus:ring-primary-300 dark:bg-primary-600 dark:hover:bg-primary-700 dark:focus:ring-primary-800"
      >
        Save Questions
      </button>
      {error && <p className="mt-4 text-red-600">{error}</p>}
    </div>
  );
};

export default CreateQuestionsPage;
