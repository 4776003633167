import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import "./AllFeedbackTable.css"; // Import the CSS file
import { Table } from "flowbite-react";

const AllFeedbackTable = () => {
  const { companyId } = useParams();
  const [employees, setEmployees] = useState([]);
  const [feedbackByEmployee, setFeedbackByEmployee] = useState({});
  const [averageRatingsByEmployee, setAverageRatingsByEmployee] = useState({});
  const [commonAverageRating, setCommonAverageRating] = useState(0); // New state for common average rating
  const [error, setError] = useState(null);

  useEffect(() => {
    fetchEmployeeData();
    fetchFeedbackData();
  }, [companyId]);

  const fetchEmployeeData = async () => {
    const token = localStorage.getItem("token");
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/companies/${companyId}/employees`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        console.error("HTTP error response:", errorText);
        setError(`Error: ${response.status} - ${errorText}`);
        return;
      }

      const data = await response.json();
      setEmployees(data.employees || []);
    } catch (error) {
      console.error("Failed to fetch employees:", error);
      setError("Failed to fetch employees.");
    }
  };

  const fetchFeedbackData = async () => {
    const token = localStorage.getItem("token");
    if (!token) {
      setError("Authentication token is missing.");
      return;
    }

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}/api/feedback/company/${companyId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      if (!response.ok) {
        const errorText = await response.text();
        console.error("HTTP error response:", errorText);
        setError(`Error: ${response.status} - ${errorText}`);
        return;
      }

      const data = await response.json();
      console.log("Fetched feedback data:", data);

      const feedbackMap = data.feedback.reduce((map, item) => {
        if (!map[item.employeeId]) {
          map[item.employeeId] = [];
        }
        map[item.employeeId].push(item);
        return map;
      }, {});

      setFeedbackByEmployee(feedbackMap);

      // Calculate average ratings per employee
      const avgRatings = {};
      let totalSum = 0;
      let totalCount = 0;

      Object.keys(feedbackMap).forEach((employeeId) => {
        const ratings = feedbackMap[employeeId]
          .map((item) => parseFloat(item.response))
          .filter((num) => !isNaN(num));
        const employeeAvg = calculateAverageRating(ratings) * 2; // Adjust to out of 10
        avgRatings[employeeId] = employeeAvg;

        // Accumulate total sum and count for common average rating
        totalSum += ratings.reduce((acc, rating) => acc + rating, 0);
        totalCount += ratings.length;
      });

      setAverageRatingsByEmployee(avgRatings);

      // Calculate common average rating
      const commonAvg = totalCount > 0 ? (totalSum / totalCount) * 2 : 0;
      setCommonAverageRating(commonAvg);
    } catch (error) {
      console.error("Failed to fetch feedback:", error);
      setError("Failed to fetch feedback.");
    }
  };

  // Calculate average rating
  const calculateAverageRating = (responses) => {
    const numericResponses = responses
      .map((response) => parseFloat(response))
      .filter((num) => !isNaN(num));
    if (numericResponses.length === 0) return 0;
    const sum = numericResponses.reduce((acc, rating) => acc + rating, 0);
    return sum / numericResponses.length;
  };

  // Function to get color based on average rating
  const getColor = (rating) => {
    if (rating >= 8) return "#4caf50"; // Green for high ratings
    if (rating >= 6) return "#1a0b67"; // Yellow for medium ratings
    return "#f44336"; // Red for low ratings
  };

  return (
    <div className="p-4">
      <h2 className="text-4xl text-gray-800 mb-4">All Feedback</h2>

      {error && <p className="text-red-500 mb-4">{error}</p>}

      {/* New section to display common average rating */}
      <div className="mb-8 flex justify-center items-center">
        <div className="w-24 h-24">
          <CircularProgressbar
            value={commonAverageRating}
            text={`${commonAverageRating.toFixed(1)}/10`}
            styles={buildStyles({
              pathColor: getColor(commonAverageRating),
              textColor: "#1a0b67",
              trailColor: "#ddd",
            })}
            maxValue={10} // Set maxValue to 10
          />
        </div>
      </div>

      <div className="overflow-x-auto">
        <Table
          striped={true}
          className="w-full border border-gray-200 rounded-lg bg-white shadow-md"
        >
          <thead className="bg-gray-100 text-gray-600">
            <tr>
              <th className="px-4 py-2 text-left">Employee ID</th>
              <th className="px-4 py-2 text-left">Name</th>
              <th className="px-4 py-2 text-left">Email</th>
              <th className="px-4 py-2 text-left">Question</th>
              <th className="px-4 py-2 text-left">Response</th>
              <th className="px-4 py-2 text-left">Avg. Rating</th>
            </tr>
          </thead>
          <tbody>
            {employees.map((employee, index) => {
              const feedback = feedbackByEmployee[employee.id] || [];
              const avgRating = averageRatingsByEmployee[employee.id] || 0;

              return feedback.length > 0 ? (
                <>
                  {feedback.map((item, feedbackIndex) => (
                    <tr
                      key={`${employee.id}-${feedbackIndex}`}
                      className="border-b border-gray-200"
                    >
                      {feedbackIndex === 0 && (
                        <>
                          <td
                            rowSpan={feedback.length}
                            className="px-4 py-2 border-t border-gray-200"
                          >
                            {index + 1} {/* Use index + 1 as the frontend ID */}
                          </td>
                          <td
                            rowSpan={feedback.length}
                            className="px-4 py-2 border-t border-gray-200"
                          >
                            {employee.name} {employee.lastName}{" "}
                            {/* Display employee's first and last name */}
                          </td>
                          <td
                            rowSpan={feedback.length}
                            className="px-4 py-2 border-t border-gray-200"
                          >
                            {employee.email} {/* Display employee's email */}
                          </td>
                        </>
                      )}
                      <td className="px-4 py-2 border-t border-gray-200">
                        {item.question}
                      </td>
                      <td className="px-4 py-2 border-t border-gray-200">
                        {item.response}
                      </td>
                      {feedbackIndex === 0 && (
                        <td
                          rowSpan={feedback.length}
                          className="px-4 py-2 border-t border-gray-200"
                        >
                          <div className="w-16 h-16 mx-auto">
                            <CircularProgressbar
                              value={avgRating}
                              text={`${avgRating.toFixed(1)}/10`}
                              styles={buildStyles({
                                pathColor: getColor(avgRating),
                                textColor: "#1a0b67",
                                trailColor: "#ddd",
                              })}
                              maxValue={10} // Set maxValue to 10
                            />
                          </div>
                        </td>
                      )}
                    </tr>
                  ))}
                </>
              ) : null; // Skip rendering if no feedback
            })}
          </tbody>
        </Table>
      </div>
    </div>
  );
};

export default AllFeedbackTable;
